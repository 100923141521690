@keyframes AppWillUnmountInc {
    0%{transform:scale(1)}
    20%{transform:scale(0.85)}
    30%{transform:scale(0.8); margin-top: 105px;}
    50%{margin-left: 0}
    60%{margin-left: -5%}
    70%{margin-left: -10%}
    80%{margin-left: -25%}
    100%{transform:scale(0.8); margin-left: -100%; margin-top: 105px}
}
@keyframes AppWillUnmountDec {
    0%{transform:scale(1)}
    20%{transform:scale(0.85)}
    30%{transform:scale(0.8); margin-top: 105px;}
    50%{margin-left: 0}
    60%{margin-left: 5%}
    70%{margin-left: 10%}
    80%{margin-left: 25%}
    100%{transform:scale(0.8); margin-left: 100%; margin-top: 105px;}
}
@keyframes AppWillMountInc {
    0%{transform:scale(0.8);  margin-left: 100%; margin-top: 105px;}
    20%{margin-left: 25%}
    30%{margin-left: 10%}
    40%{margin-left: 5%}
    50%{margin-left: 0}
    70%{transform:scale(0.8); margin-top: 105px;}
    80%{transform:scale(0.85)}
    100%{transform:scale(1)}
}
@keyframes AppWillMountDec {
    0%{transform:scale(0.8);  margin-left: -100%; margin-top: 105px;}
    20%{margin-left: -25%}
    30%{margin-left: -10%}
    40%{margin-left: -5%}
    50%{margin-left: 0}
    70%{transform:scale(0.8); margin-top: 105px;}
    80%{transform:scale(0.85)}
    100%{transform:scale(1)}
}
@keyframes transitionPageActiveAnimation {
    0%{background-color: rgba(100,120,140,0)}
    40%{background-color: rgba(100,120,140,0.2)}
    100%{background-color: rgba(100,120,140,0.2)}
}
@keyframes transitionPageDisableAnimation {
    0%{background-color: rgba(100,120,140,0.2)}    
    60%{background-color: rgba(100,120,140,0.2)}
    100%{background-color: rgba(100,120,140,0)}
}

@mixin transitionPage {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1001;
}

.transitionPage {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    overflow: hidden;
}

.transitionPage-active {
    @include transitionPage;
    animation: transitionPageActiveAnimation 1s linear;
    animation-fill-mode: forwards;
}
.transitionPage-disable {
    @include transitionPage;
    animation: transitionPageDisableAnimation 0.5s linear;
    animation-fill-mode: forwards;
}

.App-Will-Unmount-Dec {
    animation: AppWillUnmountDec 0.5s linear;
    animation-fill-mode: forwards;
    transform-origin: top;
}
.App-Will-Unmount-Inc {
    animation: AppWillUnmountInc 0.5s linear;
    animation-fill-mode: forwards;
    transform-origin: top;
}
.App-Will-Mount-Dec {
    animation: AppWillMountDec 0.5s linear; 
    transform-origin: top;
  }
.App-Will-Mount-Inc {
    animation: AppWillMountInc 0.5s linear;
    transform-origin: top;
}