@import '../../../App.scss';
.firstLoadPage {
    background-color: $mainColor2;
    display: block;
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1100;
    opacity: 1;
    transition: opacity 0.3s ease-in, height 0.1s linear 0.3s;
}
.firstLoadAnimation {
    opacity: 0;
    height: 0;
}