@import '../../App.scss';

.section {
    display: block;
    position: relative;
    width: 100%;
}
.header {
    padding-top: 100px;
    height: calc(100vh - 100px);
    position: inherit;
    overflow: hidden;
}
.footer {
    padding-bottom: 1px;
    .footerButton {
        margin-right: 0!important;
    }
    p {
        color: $mainColor2;
        padding-top: 5px;
        font-size: 1.1rem;
        line-height: 1.6rem;
        margin-bottom: 5px;
        &:last-child {
            text-transform: none;
            font-size: 0.8rem;
            letter-spacing: 0;
            margin-top: 16px;
            margin-bottom: 5px;
        }
    }
}

.logoVertical {
    padding-top: 30px;
    width: 250px;
}