$mainColor1: #e0e0e0;
$mainColor2: #f2f2f2;
$mainColor3: #111010;
$mainColor4: #00b0f0;
$mainColor5: #f45000;
$mainColor6: #fa194c;
$menuColorTransition:  color 0.2s linear, fill 0.2s linear;


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@mixin setP {
  text-align: left;
  max-width: 900px;
  display: block;
  padding: 8px;
  margin: 0 auto;
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  src: './assets/fonts/OpenSans-Regular-webfont.woff';
}

@mixin headerMediaMobileBackground {
  width: 100vw;
  filter: blur(0);
  background-position: center;
  &:before {
      content: "";
      display: block;
      height: 100%;
      width: 100%;
      background-color: rgba($mainColor1, 0.4);
  }
}
@mixin headerMediaMobileIntroduction {
  top: calc(50vh - 230px);
  width: 90vw;
  max-width: 700px;
  margin: 0 auto;
  text-align: center;
}

html {
  scroll-behavior: smooth;
}
body, html, h1, h2, h3 {
  color: $mainColor3;
}
h2 {
  font-size: 1.9rem;
  letter-spacing: -1px;
  line-height: 2.3rem;
}
li {
  list-style-type: square;
}
.App {
  background-color: $mainColor1;
  text-align: center;
  display: block;
  width: 100%;
  position: relative;
}
body {
  background-color: lighten( $mainColor3, 10% );
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  //background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  //color: white;
}

.App-link {
  color: #61dafb;
}

.fade {
  position: absolute;
  top: 0;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}
.section_title {
  margin-bottom: 20px;
  text-transform: uppercase;
}
.underline {
  display: block;
  width: 45px;
  height: 2px;
  margin: 0 auto;
  margin-bottom: 30px;
}