@import "../../../App.scss";

.button {
    margin-top: 14px;
    margin-right: 20px;
    display: inline-block;
    position: relative;
    padding: 12px 20px 11px 20px;
    text-align: center;
    min-width: 180px;
    font-size: 1.2rem;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
}

.arrowButton {
    display: block;
    position: absolute;
    right: 20px;
    min-width: 0;
    font-size: 3.8rem;
    padding: 0;
    width: 80px;
    height: 80px;
    border-radius: 50px;
    border: 8px solid $mainColor3 ;
    margin-top: -44px;
    &::after {
        content: '^';
        display: block;
        transform: scale(1.2, 0.9);
    }
    &::before {
        display: none!important;
    }
}

.button::before {
    content: "";
    display: block;
    position: absolute;
    margin: -12px 0 0 -20px;
    width: 0;
    height: 100%;
    background-color: rgba(255,255,255,0);
    transition: background-color 0.25s linear, width 0.25s linear;
}
.button:hover::before {
    background-color: rgba(255,255,255,0.20);
    width: 100%;
}