@import "../../App.scss";

$burgerLeft: 120px;

@keyframes burgerSlide {
    0% {margin-left: $burgerLeft;}
    70% {margin-left: -15px;}
    100% {margin-left: 0px;}
}

@mixin burgerSlice($marginTop, $delay) {
    position: absolute;
    width: 40px;
    height: 6px;
    background-color: $mainColor3;
    margin-top: $marginTop;
    margin-left: $burgerLeft;
    border-radius: 3px;
    animation-name: burgerSlide;
    animation-duration: 0.3s;
    animation-delay: $delay;
    animation-fill-mode: forwards;
}
@mixin hover {
    left: calc(50% - 30px);
    width: 60px;
}
.burger{
    > .burgerIco {
        display: block;
        position: absolute;
        top: 4px;
        right: 0;
        width: 40px;
        padding: 20px;
        height: 40px;
        z-index: 1000;
        cursor: pointer;
        overflow: hidden;
        > div {
            @include burgerSlice(0, 0.3s);
            &:before {
                content: "";
                @include burgerSlice(11px, 0.4s);
            }
            &:after {
                content: "";
                @include burgerSlice(22px, 0.5s);
            }
        }
    }
    > .burgerMenu {
        display: block;
        position: fixed;
        width: 100%;
        height: 100vh;
        background-color: $mainColor2;
        z-index: 1001;
        transition: top 0.2s ease-out;
        text-align: center;
        .logo_horizontal {
            display: block;
            position: absolute;
            top: 10px;
            left: 40px;
            width: 250px;
            z-index: 1000;
        }
        > a {
            display: block;
            text-align: center;
            height: 40px;
            width: 180px;
            margin: 20px auto;
            font-weight: bold;
            text-decoration: none;
            font-size: 1.4rem;
            > div > p {
                display: block;
                margin: 0;
            }
            &:first-child {
                display: block;
            }
            &:nth-child(2) {
                margin-top: calc(50vh - 270px);
            }
            &:nth-child(7) {
                margin-bottom: 50px;
            }
            &:nth-child(8), &:nth-child(9), &:nth-child(10) {
                display: inline-block;
                margin: 0 14px;
                height: 30px;
                width: 30px;
            }
            &:after{
                content: "";
                position: absolute;
                margin-top: 3px;
                left: 50%;
                width: 0;
                border-bottom: 2px $mainColor5 solid;
                transition: width 0.15s linear, left 0.15s linear;
            }
            &.active:after {
                @include hover
            }
            &:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5), &:nth-child(6), &:nth-child(7) {
                &:hover::after{
                    @include hover
                }
            }
        }
    }
    > a {
        > .logo_horizontal {
            display: block;
            position: absolute;
            top: 10px;
            left: 40px;
            width: 250px;
            z-index: 1000;
    }}
}

@media all and (max-height: 500px) {
.burger {
    > .burgerMenu {
        > a {
            font-size: 1rem;
            margin: 10px auto;
            height: 30px;
            
            &:nth-child(2){
                margin-top: calc(50vh - 190px);
            }
            &:nth-child(7) {
                margin-bottom: 20px;
            }
        }
    }
}
}