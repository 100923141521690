@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  src: './assets/fonts/OpenSans-Regular-webfont.woff';
}
body, html {
  margin: 0;
  font-size: 16px;
}
body, html, div, a, p, span, h1, h2, h3, h4, h5, h6, article, section, footer, header, ul, li {
  font-family: 'Open Sans', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
}
input, textarea {
  outline: none;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}