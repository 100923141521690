@media all and (max-width: 1250px) {
    .main > .navigation {
        left: calc(50vw - 550px);
        text-align: left;
    }
    .navigation > a {
        &:first-child {
            width: calc(20vw)
        }
    }
    .navigation .socialIcon3 {
        margin-left: -50px;
    }
}
@media all and (max-width: 800px) {
    h2 {
        font-size: 1.6rem !important;
    }
}
@media all and (max-width: 400px) {
    .footer {
        .arrowButton {
            margin-right: 0px;
        }
    }
}
@media all and (max-height: 600px) {
    .header {
        min-height: 500px;
        > div {
            min-height: 600px;
            h2 {
                font-size: 1.6rem;
            }
        }
        .about-header, .skills_header, .portfolio_header, .consulting_header {
            top: 80px;
            min-height: 520px;
        }
        .home-header {
            top: 120px;
            min-height: 480px;
        }
    }
}