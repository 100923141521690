@import "../../App";

@mixin keyframes($name, $val1, $val2) {
    @keyframes #{$name} {
        0% {opacity: 0;}
        #{$val1} {opacity: 0; margin-left: 60px;}
        #{$val2} {opacity: 1;  margin-left: 30px}
    }
}

@include keyframes(menuAnimation1, 45%, 60%);
@include keyframes(menuAnimation2, 55%, 70%);
@include keyframes(menuAnimation3, 65%, 80%);
@include keyframes(menuAnimation4, 75%, 90%);
@include keyframes(menuAnimation5, 85%, 100%);

@keyframes socialMediaAnimation {
    0% {opacity: 0;}
    85% {opacity: 0; margin-left: 10px;}
    100% {opacity: 1;}
}

@mixin hover {
    left: calc(50% - 16px);
    width: 34px;
}

.main {
    background-color: $mainColor1;
    color: $mainColor3;
}
.main > .navigation {
    display: block;
    position: absolute;
    z-index: 1000;
    left: calc(50vw - 550px);
    text-align: right;
}
.main > .navigation a{
    font-family: 'Open Sans';
    font-weight: bold;
    font-size: 0.85rem;
    letter-spacing: -0.5px;
    display: inline-block;
    margin: 15px 30px;
    text-decoration: none;
    transition: $menuColorTransition;
    transform: scale(1.1, 1);
    animation-duration: 2s;
    cursor: pointer;
    &:first-child{
        transform: none;
    }
    &:nth-child(2) {
        display: none;
    }
    &:after{
        content: "";
        position: absolute;
        margin-top: -10px;
        left: 50%;
        width: 0;
        border-bottom: 2px $mainColor5 solid;
        transition: width 0.15s linear, left 0.15s linear;
    }
    &.active:after {
        @include hover
    }
    &:nth-child(3) {
        animation-name: menuAnimation1;
        &:hover::after{
            @include hover
        }
    }
    &:nth-child(4) {
        animation-name: menuAnimation2;
        &:hover::after{
            @include hover
        }
    }
    &:nth-child(5) {
        animation-name: menuAnimation3;
        &:hover::after{
            @include hover
        }
    }
    &:nth-child(6) {
        animation-name: menuAnimation4;
        &:hover::after{
            @include hover
        }
    }
    &:nth-child(7) {
        animation-name: menuAnimation5;
        margin-right: 15px;
        &:hover::after{
            @include hover
        }
    }
}
.navigation .socialIcon1 {
    width: 20px;
    margin-bottom: -3px;
    margin-right: -35px;
    animation-name: socialMediaAnimation;
    animation-duration: 2.4s;
}
.navigation .socialIcon2 {
    width: 20px;
    margin-bottom: -3px;
    margin-left: -25px;
    margin-right: -18px !important;
    animation-name: socialMediaAnimation;
    animation-duration: 2.4s;
}
.navigation .socialIcon3 {
    width: 20px;
    margin: 0 0 -3px -25px;
    animation-name: socialMediaAnimation;
    animation-duration: 2.4s;
}
.socialIconPath {
    transition: $menuColorTransition;
}