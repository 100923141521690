@import "../../App";

.Notebook {
    display: block;
    position: absolute;
    z-index: 1;
    background-image: url('../../assets/images/Notebook.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left;
    right: 0;
    top: 0;
    width: 57vw;
    height: 100vh;
}
.home-header {
    display: block;
    position: absolute;
    text-align: left;
    z-index: 2;
    width: 630px;
    top: calc(50vh - 180px);
    left: calc(50vw - 550px);
    h2, h3 {
        display: inline;
        text-transform: uppercase;
    }
    p {
        display: inline;
        font-size: 1.1rem;
    }
}
.brands {
    min-height: 130px;
    padding-top: 20px;
    padding-bottom: 30px;
    img {
        height: 100px;
        padding: 5px;
    }
}

// Media
@media all and (max-height: 600px) {
    .Notebook {
        width: 63vw;
    }
}
@media all and (max-width: 1100px) {
    .home-header {
        left: 20px;
        top: calc(50vh - 220px);
        width: 50vw;
        max-width: 680px;
    }
}
@media all and (max-width: 800px) {
    .Notebook {
        @include headerMediaMobileBackground
    }
    .home-header {
        @include headerMediaMobileIntroduction
    }
    .brands {
        img {
            height: 75px;
            filter: grayscale(1) brightness(1.1);
        }
    }
}